import React from "react";

export const Services = (props) => {
  return (
    <header id="services" >
      <div className="intro" >
        <div className="overlay">
          <div className="container" >
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text-services">
             
              <p>Tarifs</p>  
                <br />
                <p>Système de base &nbsp;&nbsp;&nbsp;&nbsp; Gratuit</p>
                
                <a href="adduser" className="btn btn-custom btn-lg page-scroll">
                  S'inscrire
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
