import React from "react";

export const About = (props) => {
  return (
    <header id="about" >
      <div className="intro" >
        <div className="overlay">
          <div className="container" >
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text-about">
              <h1>{props.data ? props.data.title : "Loading"}</h1>
              <div>
                <p>{props.data ? props.data.Introduction : "Loading"}</p>
                <p>{props.data ? props.data.Features : "Loading"}</p>
                <p>{props.data ? props.data.Development : "Loading"}</p>
                <p>{props.data ? props.data.Company : "Loading"}</p>
                <p style={{ fontWeight: 'bold' }}>{props.data ? props.data.mod : "Loading"}</p>
                <p>{props.data ? props.data.sys : "Loading"}</p>

                <p>{props.data ? props.data.FollowUs : "Loading"}</p>
              </div>
              
                <a href="adduser" className="btn btn-custom btn-lg page-scroll">
                  S'inscrire
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
