import React, { useEffect } from "react";

export const Header = (props) => {
  
  useEffect(() => {
    // Load the Facebook SDK
    const script = document.createElement('script');
    script.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v10.0';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    // Initialize the SDK after it loads
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: 'your-app-id', // Optional app-specific features
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v10.0'
      });
    };
  }, []);

  return (
    <header id="header" style={{ backgroundImage: `url(${props.data ? props.data.backgroundImage : ''})` }}>
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1 style={{ color: "black" }}>
                  {props.data ? props.data.title : "Loading"}
                </h1>
                <p style={{ color: "black" }}>{props.data ? props.data.paragraph : "Loading"}</p>
                
                <a href="adduser" className="btn btn-custom btn-lg page-scroll">
                  S'inscrire
                </a>

                {/* Facebook Page Plugin (without scroll) */}
                <div style={{ marginTop: '50px' }}>
                  <div className="fb-page"
                    data-href="https://www.facebook.com/mmarokkobizinvoicesystem/?_rdc=1&_rdr"
                    data-width="340"
                    data-height="130" // Reduced height to remove scrolling
                    data-small-header="false"
                    data-adapt-container-width="true"
                    data-hide-cover="false"
                    data-show-facepile="true"> {/* Only the Like Box, no timeline */}
                    <blockquote
                      cite="https://www.facebook.com/mmarokkobizinvoicesystem/?_rdc=1&_rdr"
                      className="fb-xfbml-parse-ignore">
                      <a href="https://www.facebook.com/mmarokkobizinvoicesystem/?_rdc=1&_rdr">Facebook Page</a>
                    </blockquote>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
