import React from "react";

export const Navigation = ({ setCurrentSlide }) => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
              
 
      <div className="container">
        <div className="navbar-header">
        
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
         
          <a className="navbar-brand page-scroll" href="#page-top" onClick={() => setCurrentSlide("header")}>
           
            Marokko Biz Invoice System 
          </a>{" "}
        
        
            <p className="copyright">Copyright all rights reserved. Part of Marokko Biz of 31.01.12 SARL. </p>
            <p className="version">version 1.0</p>

        </div>

        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="#page-top" className="page-scroll" onClick={() => setCurrentSlide("header")}>
                Accueil
              </a>
            </li>
            <li>
              <a href="#about" className="page-scroll" onClick={() => setCurrentSlide("about")}>
                À propos de nous
              </a>
            </li>
            <li>
              <a href="#services" className="page-scroll" onClick={() => setCurrentSlide("services")}>
                Tarifs
              </a>
            </li>
            <li>
              <a href="#contact" className="page-scroll" onClick={() => setCurrentSlide("contact")}>
                Contact
              </a>
            </li>
            <li>
              <a href="adduser" className="page-scroll">
                Inscription
              </a>
            </li>
            <li>
              <a href="login" className="page-scroll">
                Connexion
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
