import React, { useState } from "react";
import './Contact.css'; // Import your CSS file

export const Contact = (props) => {
  // State to control the popup visibility
  const [showPopup, setShowPopup] = useState(false);

  // Function to toggle the popup visibility
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <div id="contact">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text-contact">
                <p>
                  <span>
                    <i className="fa fa-map-marker"></i> Address
                  </span>
                  <div className="compact-text">
                    {props.data ? (
                      <>
                        <p>{props.data.address}</p>
                        <p>{props.data.address1}</p>
                        <p>{props.data.address2}</p>
                        <p>{props.data.address3}</p>
                      </>
                    ) : (
                      "loading"
                    )}
                  </div>
                </p>
                <p>
                  Hotline Whatsapp +212(0)769537402 <i>Messages uniquement</i>
                </p>
                <div className="contact-item">
                  <p>
                    <span>
                      <i className="fa fa-envelope-o"></i> Email
                    </span>{" "}
                    <a
                      href={`mailto:${props.data ? props.data.email : "info@marokkobizinvoice.com"}`}
                    >
                      {props.data ? props.data.email : "loading"}
                    </a>
                    <br></br> <br></br>
                    <div className="social">
                      <a
                        href={props.data ? props.data.youtube : "https://www.youtube.com/@MarokkoBizInvoicesystem"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa fa-youtube"></i>
                      </a>
                      <a
                        href={props.data ? props.data.facebook : "https://www.facebook.com/mmarokkobizinvoicesystem/?_rdc=1&_rdr"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa fa-facebook"></i>
                      </a>
                    </div>
                    <a href="adduser" className="btn btn-custom btn-lg page-scroll">
                      S'inscrire
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Credit button to trigger the popup */}
        <button className="credit-button" onClick={togglePopup}>
          Credit
        </button>

        {/* Popup Modal */}
        {showPopup && (
          <div className="popup-overlay">
            <div className="popup-content" style={{ backgroundImage: "url('landing/img/intro-bg.jpg')" }}>
              <button className="close-button" onClick={togglePopup}>
                X
              </button>
              <h3>Credits</h3>
              <br /><br />
              <table>
                <tbody>
                  <tr>
                    <td>Klaus Kristoffersen</td>
                    <td>LinkedIn</td>
                    <td>Chief Responsible</td>
                  </tr>
                  <tr style={{ height: '5px' }}></tr>
                  <tr>
                    <td>Asma'e El Abbassi</td>
                    <td>
                      <a
                        href="https://www.linkedin.com/in/asma-e-el-abbassi-966920241"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        LinkedIn
                      </a>
                    </td>
                    <td>Developer (B/F)</td>
                  </tr>
                  <tr style={{ height: '5px' }}></tr>
                  <tr>
                    <td>Noura Berhimou</td>
                    <td>LinkedIn</td>
                    <td>Developer (B/F)</td>
                  </tr>
                  <tr style={{ height: '5px' }}></tr>
                  <tr>
                    <td>Houda Kaïsso</td>
                    <td>LinkedIn</td>
                    <td>Developer (B)</td>
                  </tr>
                  <tr style={{ height: '5px' }}></tr>
                  <tr>
                    <td>Nour El Yakin Bassayiene</td>
                    <td>
                      <a
                        href="https://ma.linkedin.com/in/nour-el-yakine-bassayiene-4bb627305"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        LinkedIn
                      </a>
                    </td>
                    <td>Developer (B)</td>
                  </tr>
                  <tr style={{ height: '5px' }}></tr>
<tr>
  <td>Aïcha Njimate</td>
  <td>
    <a href="https://www.linkedin.com/in/aicha-njimate-846aa5245?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" 
       target="_blank" 
       rel="noopener noreferrer">
      Linkedin
    </a>
  </td>
  <td>Developer (B/F)</td>
</tr>
<tr>
  <td>Oumkaltoum El Yaqoubi </td>
  <td>
    <a href="https://www.linkedin.com/in/oumkaltoum-el-yaqoubi" 
       target="_blank" 
       rel="noopener noreferrer">
      Linkedin
    </a>
  </td>
  <td>Developer (F)</td>
</tr>


                  {/* Add more rows as necessary */}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
