import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [currentSection, setCurrentSection] = useState("header");

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  const renderSection = () => {
    switch (currentSection) {
      case "about":
        return <About data={landingPageData.About} />;
      case "services":
        return <Services data={landingPageData.Services} />;
      case "contact":
        return <Contact data={landingPageData.Contact} />;
      default:
        return <Header data={landingPageData.Header} />;
    }
  };

  return (
    <div>
      <Navigation setCurrentSlide={setCurrentSection} />
      <div className="slideshow-content">
        {renderSection()}
      </div>
    </div>
  );
};

export default App;
